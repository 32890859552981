import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Contact from "../components/contact";
import Clients from "../components/clients";
import About from "../components/about";
import Realisations from "../components/realisations";
import Welcome from "../components/welcome";
import TitleTextBlock from "../components/title-text-block";
import Tryptych from "../components/tryptych";
import { useRef } from "react";
import React from "react";



import serviceLeft from '../public/images/Services/Communication.png'
import serviceMiddle from '../public/images/Services/Illustration.png'
import serviceRight from '../public/images/Services/Motion.gif'
// Images

function HomePage() {
    const { t } = useTranslation();

    const myRef = React.useRef(document.createElement("div"));

    const executeScroll = () => myRef.current.scrollIntoView();
    return (
        <>
            <header>
                <Welcome textTop="header-title.top" textBottom="header-title.bottom"/>
                <i className="Arrow down" onClick={executeScroll}></i>
            </header>
            <section ref={myRef} className="Background-light">
            <div className="w-75 m-auto">
                <Tryptych
                    left={{title: "services.presentation.left.title", text: "services.presentation.left.text", image: serviceLeft}}
                    middle={{title: "services.presentation.middle.title", text: "services.presentation.middle.text", image: serviceMiddle}}
                    right={{title: "services.presentation.right.title", text: "services.presentation.right.text", image: serviceRight}}/>
                <Button variant='light' href="/services" className="Button-primary Text-light m-auto">{t("button.services")}</Button>
                    
                </div>
            </section>
            <section>
                <div className="pt-5 w-75 m-auto">
                    <Clients></Clients>
                </div>
            </section>
            <section className="Background-light">
                <div className="w-75 m-auto ">
                <Realisations></Realisations>
                </div>
            </section>
            <section>
                <About></About>
            </section>
            
            <section className="Background-light">
                <div className="w-75 m-auto ">
                    <TitleTextBlock title="advertising.title" text="advertising.text"/>
                </div>
            </section>
            <section>
                <Contact></Contact>
            </section>

        </>
    )
  }

export default HomePage;